import axios from 'axios';

const baseUrl = process.env.NEXT_PUBLIC_REST_API_URI;

/* Hash table */
//export const findByTableAndId = (table, id) => axios.get(baseUrl + `/api/cases/${table}/${id}`);
//export const findByHash = (hash) => axios.get(baseUrl + `/api/cases/${hash}`);

/* Cases */
export const getCaseByTableAndId = (table, id, header) =>
  axios.get(baseUrl + `/api/cases/${table}/${id}`, { headers: header });
export const getCaseByHash = (hash, header) => axios.get(baseUrl + `/api/cases/${hash}`, { headers: header });
export const getCaseContentsByHash = (hash, header) =>
  axios.get(baseUrl + `/api/cases/contents/${hash}`, { headers: header });
export const getCaseEnContentsByHash = (hash, header) =>
  axios.get(baseUrl + `/api/cases/contents/en/${hash}`, { headers: header });
export const getCaseContentsByTableAndId = (table, id, header) =>
  axios.get(baseUrl + `/api/cases/contents/${table}/${id}`, { headers: header });
export const getCaseEnContentsByTableAndId = (table, id, header) =>
  axios.get(baseUrl + `/api/cases/contents/en/${table}/${id}`, { headers: header });

export const getSimilarCasesByTableAndId = (table, id, header) =>
  axios.get(baseUrl + `/api/similar-cases/${table}/${id}`, { headers: header });
export const getSimilarCasesByHash = (hash, header) =>
  axios.get(baseUrl + `/api/similar-cases/${hash}`, { headers: header });

/* Reference */
export const getRefContentsByTableAndId = (table, id, header) =>
  axios.get(baseUrl + `/api/cases/contents/ref/${table}/${id}`, { headers: header });
export const getRefContensByHash = (hash, header) =>
  axios.get(baseUrl + `/api/cases/contents/ref/${hash}`, { headers: header });

/* Posts */
// eslint-disable-next-line no-empty-pattern
export const createPost = ({}) => axios.post(baseUrl + `/api/posts/create`, {}, { withCredentials: true });
export const getPostsByHash = ({ hash }, header) =>
  axios.post(baseUrl + `/api/posts/get/byHash`, { hash }, { headers: header });
export const getPostsByTitle = ({ title }, header) =>
  axios.post(baseUrl + `/api/posts/get/byTitle`, { title }, { headers: header });
/* searchPosts 로 대체 */
// export const getPostsList = ({ from, size }) =>
//   axios.post(baseUrl + '/api/posts/list/get/all', { from, size }, { withCredentials: true });
export const getPostsListByUser = ({ from, size }) =>
  axios.post(baseUrl + '/api/posts/list/get/byUser', { from, size }, { withCredentials: true });
export const updatePosts = ({ hash, author, title, description, content }) =>
  axios.post(baseUrl + `/api/posts/update`, { hash, author, title, description, content }, { withCredentials: true });
export const searchPosts = ({ query, from, size }) =>
  axios.post(baseUrl + '/api/search/posts', { query, from, size }, { withCredentials: true });
export const deletePosts = (hash) => axios.delete(baseUrl + `/api/posts/${hash}`, { withCredentials: true });
export const updateIsPublish = ({ hash, is_publish }) =>
  axios.patch(baseUrl + '/api/posts/publication', { hash, is_publish }, { withCredentials: true });
export const postsAuthority = () => axios.get(baseUrl + '/api/posts/authority', { withCredentials: true });
/* Laws */
export const getLawContentsById = (title, enfDate, header) =>
  axios.get(baseUrl + `/api/law/contents/${title}/${enfDate}`, { headers: header });

/* Link */
export const getLinkHash = (hash, header) => axios.get(baseUrl + `/api/link/node/${hash}`, { headers: header });
export const getLawReference = ({ title, article, category, size, from }) =>
  axios.post(baseUrl + '/api/link/law', { title, article, category, size, from }, { withCredentials: true });

// export const getLawListById = (id) => axios.get(baseUrl + `/api/law/list/${id}`);
