import React, { createContext, useState, useEffect, useContext } from 'react';
import * as AuthAPI from '@src/lib/api/auth';
import JWT from 'jsonwebtoken';
import { getUserInfo } from '../lib/api/auth';

const AuthContext = createContext({
  userInfo: null,
});

export const AuthProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        let res = await getUserInfo();
        let newUserInfo = res.data.data;
        if (JSON.stringify(userInfo) != JSON.stringify(newUserInfo)) {
          setUserInfo(newUserInfo);
        }
      } catch (err) {
        // console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [userInfo]);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        userInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (typeof context === 'undefined') {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
