import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'intersection-observer';
import React, { useEffect } from 'react';
import { AuthProvider } from '../providers/Auth';
import '../styles/main.css';
import 'bootstrap/dist/css/bootstrap.css';
import { CookiesProvider } from 'react-cookie';
import { globalStyles } from '../styles/styles';
import { loadSdk } from '@src/lib/sdk';
import { sdkList } from '@src/lib/constants';
import axios from 'axios';
import Script from 'next/script';
import * as gtag from '../lib/gtag';
// import { ThemeProvider } from '@mui/material/styles';
// import CssBaseline from '@mui/material/CssBaseline';
// import { CacheProvider } from '@emotion/react';
// import { theme } from '../styles/theme';
// import { createEmotionCache } from '@src/styles/createEmotionCache';
// import { LicenseManager } from '@ag-grid-enterprise/core';
// LicenseManager.setLicenseKey(
//   'For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-27_November_2021_[v2]_MTYzNzk3MTIwMDAwMA==bcaae24d146da8e49838739128d51330'
// );

import Cookies from 'js-cookie';
import { getUniqueId } from '@src/lib/api/content';

// Client-side cache, shared for the whole session of the user in the browser.
// const clientSideEmotionCache = createEmotionCache();

export default function MyApp({ Component, pageProps }) {
  // 비동기
  if (typeof window !== 'undefined') {
    // CSR 초기 접근 유저 cookie 셋팅
    const initialCookie = Cookies.get('unique_id');
    if (!initialCookie) {
      axios
        .get(process.env.NEXT_PUBLIC_REST_API_URI + '/api/uuid', { withCredentials: true })
        .catch((err) => console.log);
    }
  } else {
    // console.log('SSR');
  }

  useEffect(() => {
    initScript();
    const mainDomain = 'legalengine.co.kr';
    const subDomain = 'www.legalengine.co.kr';
    const online = document.URL; // 접근 URL
    if (online.match(subDomain)) {
      document.location.href = online.replace(subDomain, mainDomain);
    }

    // const jssStyles = document.querySelector('#jss-server-side');
    // if (jssStyles) {
    //   jssStyles.parentElement.removeChild(jssStyles);
    // }
  }, []);

  const initScript = async () => {
    const { kakao, jquery, payple } = sdkList;
    await loadSdk(jquery);
    await loadSdk(payple);
    await loadSdk(kakao);
    window.Kakao.isInitialized() ? {} : window.Kakao.init('37fde1b7066ebcc252075ac93855d5c9');
  };

  // return (
  //   <CookiesProvider>
  //     <AuthProvider>
  //       <CacheProvider value={emotionCache}>
  //         <ThemeProvider theme={theme}>
  //           {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
  //           {/* <CssBaseline /> */}
  //           {globalStyles}
  //           <Component {...pageProps} />
  //         </ThemeProvider>
  //       </CacheProvider>
  //     </AuthProvider>
  //   </CookiesProvider>
  // );
  return (
    <>
      <Script strategy='afterInteractive' src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`} />
      <Script
        id='gtag-init'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${gtag.GA_TRACKING_ID}', {
          page_path: window.location.pathname,
        });
      `,
        }}
      />
      <CookiesProvider>
        <AuthProvider>
          <Component {...pageProps} />
        </AuthProvider>
      </CookiesProvider>
    </>
  );
}
