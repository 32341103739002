import axios from 'axios';

const baseUrl = process.env.NEXT_PUBLIC_REST_API_URI;

/* Auth */
export const checkEmailExists = (email) => axios.get(baseUrl + '/api/auth/exists/email/' + email);
export const localRegister = ({ email, password, occupation, company, mktAgreement, phone }) =>
  axios.post(
    baseUrl + '/api/auth/register/local',
    { email, password, occupation, company, mktAgreement, phone },
    { withCredentials: true }
  );
export const socialRegister = ({ email, channel }) =>
  axios.post(baseUrl + '/api/auth/register/social', { email, channel }, { withCredentials: true });
export const localLogin = ({ email, password }) =>
  axios.post(baseUrl + '/api/auth/login/local', { email, password }, { withCredentials: true });
export const socialLogin = ({ email }) =>
  axios.post(baseUrl + '/api/auth/login/social', { email }, { withCredentials: true });
export const unregister = ({ email }) =>
  axios.post(baseUrl + '/api/auth/unregister', { email }, { withCredentials: true });
export const updatePassword = ({ email, hash, password }) =>
  axios.post(baseUrl + '/api/auth/update/password', { email, hash, password }, { withCredentials: true });
export const requestPasswordReset = (email) =>
  axios.post(baseUrl + '/api/auth/requestPasswordReset', { email }, { withCredentials: true });
export const checkStatus = () =>
  axios.get(baseUrl + '/api/auth/check?_=' + new Date().getTime(), { withCredentials: true });
export const logout = () => axios.get(baseUrl + '/api/auth/logout', { withCredentials: true });
export const verifyEmail = (hash, token, header) =>
  axios.post(baseUrl + '/api/auth/verifyEmail', { hash, token }, { headers: header });
export const checkPayment = () => axios.get(baseUrl + '/api/user/profile/payment', { withCredentials: true });
export const paymentHistory = () => axios.get(baseUrl + '/api/user/profile/payment/history', { withCredentials: true });
export const requestVerifyEmail = (email) =>
  axios.post(baseUrl + '/api/auth/requestVerifyEmail', { email }, { withCredentials: true });
export const requestSMSCodeLostAccount = ({ phoneNumber }) =>
  axios.post(baseUrl + '/requestSMSCodeLostAccount', { phoneNumber }, { withCredentials: true });
export const verifySMSCodeLostAccount = ({ phoneNumber, code }) =>
  axios.post(baseUrl + '/verifySMSCodeLostAccount', { phoneNumber, code }, { withCredentials: true });

/* Request case */
export const requestCase = ({ caseId, court, code }) =>
  axios.post(baseUrl + '/api/user/requestCase', { caseId, court, code }, { withCredentials: true });
export const getRequestCaseStatus = ({ from, size }) =>
  axios.post(baseUrl + '/api/user/getRequestCaseStatus', { from, size }, { withCredentials: true });
export const requestCaseStandard = ({ code }) =>
  axios.post(baseUrl + '/api/user/requestCaseStandard', { code }, { withCredentials: true });

/* user info */
export const getUserInfo = () => axios.get(baseUrl + '/api/user/profile', { withCredentials: true });
export const updateUserInfo = ({ id, occupation, company }) =>
  axios.post(baseUrl + '/api/user/profile', { id, occupation, company }, { withCredentials: true });
export const updatePhoneNum = ({ phone }) =>
  axios.post(baseUrl + '/api/user/profile', { phone }, { withCredentials: true });

/* user text customazation */
export const getUserTextCustomization = () =>
  axios.get(baseUrl + '/api/user/textCustomization', { withCredentials: true });
export const updateUserTextCustomization = ({ font, fontSize, fontWeight, lineHeight, letterSpacing }) =>
  axios.post(
    baseUrl + '/api/user/textCustomization',
    { font, fontSize, fontWeight, lineHeight, letterSpacing },
    { withCredentials: true }
  );

/* Bookmark */
export const addBookmarkPost = ({ title, description }) =>
  axios.post(baseUrl + '/api/user/bookmark/post/add', { title, description }, { withCredentials: true });
export const removeBookmarkPost = ({ id }) =>
  axios.post(baseUrl + '/api/user/bookmark/post/remove', { id }, { withCredentials: true });
export const updateBookmarkPostMeta = ({ bookmarkPostId, updatedTitle, updatedDescription }) =>
  axios.post(
    baseUrl + '/api/user/bookmark/post/update',
    { bookmarkPostId, updatedTitle, updatedDescription },
    { withCredentials: true }
  );
export const getBookmarkPost = ({ from, size }) =>
  axios.post(baseUrl + '/api/user/bookmark/post/get', { from, size }, { withCredentials: true });
export const isBookmarkPostExist = ({ title, description }) =>
  axios.post(baseUrl + '/api/user/bookmark/post/check', { title, description }, { withCredentials: true });
export const addBookmark = ({ bookmarkPostId, docType, caseHash, title, subtitle }) =>
  axios.post(
    baseUrl + '/api/user/bookmark/content/add',
    { bookmarkPostId, docType, caseHash, title, subtitle },
    { withCredentials: true }
  );
export const removeBookmark = ({ bookmarkPostId, docType, hash }) =>
  axios.post(
    baseUrl + '/api/user/bookmark/content/remove',
    { bookmarkPostId, docType, hash },
    { withCredentials: true }
  );
export const getBookmarkById = ({ bookmarkPostId }) =>
  axios.post(baseUrl + '/api/user/bookmark/content/get/id', { bookmarkPostId }, { withCredentials: true });
export const getBookmarkByhash = ({ hash, from, size }) =>
  axios.post(baseUrl + '/api/user/bookmark/content/get/hash', { hash, from, size }, { withCredentials: true });
export const isBookmarkExist = ({ bookmarkPostId, docType, hash }) =>
  axios.post(
    baseUrl + '/api/user/bookmark/content/check',
    { bookmarkPostId, docType, hash },
    { withCredentials: true }
  );

/* Highlight */
export const addHighlight = ({ hash, userHighlightIdx, start, end, text, color, title, subtitle }) =>
  axios.post(
    baseUrl + '/api/user/highlight/add',
    { hash, userHighlightIdx, start, end, text, color, title, subtitle },
    { withCredentials: true }
  );
export const removeHighlight = ({ hash, userHighlightIdx }) =>
  axios.post(baseUrl + '/api/user/highlight/remove', { hash, userHighlightIdx }, { withCredentials: true });
export const getHighlight = ({ hash }) =>
  axios.post(baseUrl + '/api/user/highlight/get', { hash }, { withCredentials: true });
export const getAllHighlight = ({ from, size }) =>
  axios.post(baseUrl + '/api/user/highlight/getAll', { from, size }, { withCredentials: true });

/* History */
export const addHistory = ({ hash, docType, title, subtitle }) =>
  axios.post(baseUrl + '/api/user/history/add', { hash, docType, title, subtitle }, { withCredentials: true });
export const getHistory = ({ from, size }) =>
  axios.post(baseUrl + '/api/user/history/get', { from, size }, { withCredentials: true });

/* Feedback */
export const addFeedback = ({ hash, docType, feedback }) =>
  axios.post(baseUrl + '/api/user/feedback/add', { hash, docType, feedback }, { withCredentials: true });

/* verify SMS */
export const requestPhone = ({ phoneNumber }) =>
  axios.post(baseUrl + '/requestSMSCode', { phoneNumber }, { withCredentials: true });
export const verifyPhone = ({ phoneNumber, code }) =>
  axios.post(baseUrl + '/verifySMSCode', { phoneNumber, code }, { withCredentials: true });
export const requestPhoneInsolvency = ({ phoneNumber }) =>
  axios.post(baseUrl + '/requestSMSCodeInsolvency', { phoneNumber }, { withCredentials: true });
export const verifyPhoneInsolvency = ({ phoneNumber, code }) =>
  axios.post(baseUrl + '/verifySMSCodeInsolvency', { phoneNumber, code }, { withCredentials: true });

/* payment */
export const getPayment = ({ checkout }) =>
  axios.post(baseUrl + '/api/checkout' + `${checkout}`, {}, { withCredentials: true });
export const getCheckoutResult = ({ hash }) =>
  axios.get(baseUrl + '/api/checkout/result/' + `${hash}`, { withCredentials: true });

/* block IP */
// export const blockIp = (ip, caseId) =>
// axios.get(baseUrl + '/api/block?address=' + ip + '&caseId=' + caseId, { withCredentials: true });
