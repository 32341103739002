export const sdkList = {
  kakao: {
    id: '_carillon_kakao',
    src: 'https://developers.kakao.com/sdk/js/kakao.js',
  },
  jquery: {
    id: '_carillon_jquery',
    src: 'https://code.jquery.com/jquery-3.2.1.min.js',
  },
  payple: {
    id: '_carillon_payple',
    src: 'https://cpay.payple.kr/js/cpay.payple.1.0.1.js',
  },
};
