export const loadSdk = async (sdk) => {
  const { id, src } = sdk;
  if (!document.getElementById(id)) {
    return await new Promise((resolve, reject) => {
      const js = document.createElement('script');

      js.id = id;
      js.src = src;
      js.onload = resolve;

      document.body.append(js);
    });
  }
};
